.arrow {
    border: solid black;
    border-width: 0 get-rem(3px) get-rem(3px) 0;
    display: inline-block;
    padding: get-rem(3px);
    top: get-rem(-4px);
    position: relative;
    margin-left: get-rem(12px);
}

header {
    width: 100%;
    background: $quinary-color;
    position: fixed;
    top: 0;
    z-index: 10;
}

.down {
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
}

.prenav {
    padding-top: get-rem(20px);
    padding-bottom: get-rem(21px);
    position: relative;
    z-index: 3;
    background: $quinary-color;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    &__logo {
        img {
            max-width: get-rem(210px);
            @include desktop {
                max-width: get-rem(275px);
            }
        }
    }
    .prenav-menus {
        display: none;
        @include tablet {
            display: none;
        }
        @include desktop {
            display: block;
        }
    }
}

.navbar {
    .prenav-menus {
        @include mobile {
            display: block;
        }
        @include desktop {
            display: none;
        }
    }
}

.prenav-menus {
    .prenav-menu1 {
        display: flex;
        justify-content: flex-end;
        margin-bottom: get-rem(5px);
        &__enlace {
            color: #9a9a9a;
            align-items: center;
            display: inline-flex;
            padding-left: get-rem(15px);
            border-bottom: get-rem(8px) solid $quinary-color;
            &-user {
                color: $secondary-color !important;
                font-weight: 600;
            }
            &-areaprivada {
                padding-right: 0;
                img {
                    max-width: get-rem(20px);
                    margin-left: get-rem(5px);
                }
            }
        }
    }
    .prenav-menu2 {
        display: flex;
        align-items: baseline;
        justify-content: flex-end;
        &__element {
            padding-left: get-rem(15px);
            padding-right: get-rem(15px);
            &:last-child {
                padding-right: 0;
            }
        }
        &__enlace {
            color: $tertiary-color;
            padding-bottom: get-rem(10px);
            display: flex;
            align-items: center;
            &:hover {
                border-bottom: 9px solid $secondary-color;
            }
        }
        &__icono {
            max-width: get-rem(25px);
            margin-left: get-rem(5px);
        }
    }
}

.navbar {
    border-top: 1px solid grey;
}

.csscolumns{padding: 0px;}

.nav {
    width: 100%;
    position: absolute;
    background: $quinary-color;
    transition: ease 0.5s;
    transform: translateY(-100%);
    z-index: 2;
    @include desktop {
        display: block;
        position: relative;
        transform: none;
    }
    &.openmenu {
        display: block;
    }
    &__list {
        @include desktop {
            display: flex;
            justify-content: space-between;
        }
    }
    &__option {
        position: relative;
        border-bottom: 1px solid $primary-color;
        flex-grow: 1;
        @include desktop {
            border-bottom: none;
        }
        &::after {
            @include desktop {
                content: "";
                position: absolute;
                width: 1px;
                background: $secondary-color;
                height: get-rem(50px);
                bottom: 0;
                right: 0;
            }
        }
        &:last-child {
            &::after {
                width: 0;
            }
        }
        /* &.active {
            > a {
                border-top: get-rem(8px) solid $secondary-color;
                background: #f3f3f3;
            }
        }*/
    }
    &__link {
        font-size: get-rem(16px);
        color: $quaternary-color;
        padding: get-rem(24px) get-rem(28px) get-rem(32px) get-rem(28px);
        border-top: get-rem(8px) solid $quinary-color;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        text-align: center;
        text-transform: uppercase;
        @include desktop {
            font-size: get-rem(18px);
        }
        &:first-child {
            @include mobile {}
        }
        &:hover {
            border-top: get-rem(8px) solid $secondary-color;
            background: #f3f3f3;
        }
        &.active {
            border-top: get-rem(8px) solid $secondary-color;
            background: #f3f3f3;
        }
        span.user {
            color: $secondary-color;
            font-weight: 600;
            margin-left: get-rem(20px)
        }
    }
    .has-submenu {
        transition: ease 0.3s;
        &:hover {
            background: linear-gradient(180deg, rgba(35, 83, 105, 1) 0%, rgba(18, 55, 73, 1) 100%);
            a:not(.dropdown__link) {
                color: $quinary-color;
                border-top: get-rem(8px) solid $secondary-color;
                &:hover {
                    background: linear-gradient(180deg, rgba(35, 83, 105, 1) 0%, rgba(18, 55, 73, 1) 100%);
                }
            }
            .dropdown {
                display: block;
                a {
                    border: none;
                }
            }
            .arrow {
                border: solid white;
                border-width: 0 get-rem(3px) get-rem(3px) 0;
            }
        }
    }
    .dropdown {
        display: none;
        @include desktop {
            position: absolute;
            width: get-rem(312px);
        }
        li {
            border-top: 1px solid $quinary-color;
        }
        &__link {
            padding: get-rem(30px) get-rem(22px);
            display: block;
            background: #23536a;
            color: $quinary-color;

            @include desktop {
                font-size: get-rem(18px);
                line-height: get-rem(24px);                
            }

            &:hover {
                background: #113648;
            }
        }
    }
}

.btn-menu {
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 11;
    min-height: get-rem(24px);
    padding-left: 0;
    padding-right: 0;
    border: none;
    background-color: transparent;
    color: inherit;
    cursor: pointer;
    right: get-rem(15px);
    top: get-rem(40px);
    position: absolute;
    transition: .3s ease;
    @include desktop {
        display: none;
    }
    .visually-hidden {
        display: none;
    }
    &:focus {
        outline: none;
    }
    .bars {
        display: block;
        position: relative;
        width: get-rem(37px);
        height: get-rem(4px);
        background-color: $secondary-color;
        transition: .3s;
        border-radius: get-rem(30px);
        &:before,
        &:after {
            content: "";
            display: block;
            position: absolute;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: $secondary-color;
            transition: .3s;
            border-radius: get-rem(30px);
        }
        &:before {
            transform: translate(0, -10px);
        }
        &:after {
            transform: translate(0, 10px);
        }
    }
}

.btn-menu-privatearea {
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 8;
    min-height: get-rem(24px);
    padding-left: 0;
    padding-right: 0;
    border: none;
    background-color: transparent;
    color: inherit;
    cursor: pointer;
    left: get-rem(15px);
    top: get-rem(20px);
    position: absolute;
    transition: .3s ease;
    @include desktop {
        display: none;
    }
    .visually-hidden {
        display: none;
    }
    &:focus {
        outline: none;
    }
    span {
        font-size: get-rem(18px);
        color: $secondary-color;
        padding-left: get-rem(10px);
    }
    .bars {
        display: block;
        position: relative;
        width: get-rem(28px);
        height: get-rem(3px);
        background-color: $secondary-color;
        transition: .3s;
        border-radius: get-rem(30px);
        &:before,
        &:after {
            content: "";
            display: block;
            position: absolute;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: $secondary-color;
            transition: .3s;
            border-radius: get-rem(30px);
        }
        &:before {
            transform: translate(0, -8px);
        }
        &:after {
            transform: translate(0, 8px);
        }
    }
}

.menu-privatearea-open {
    .btn-menu-privatearea {
        .bars {
            background-color: transparent;
            &:before {
                transform: rotate(45deg);
            }
            &:after {
                transform: rotate(-45deg);
            }
        }
    }
    .privatearea-menu {
        display: block;
        transform: translateY(0);
    }
}

.menu-open {
    .btn-menu {
        .bars {
            background-color: transparent;
            &:before {
                transform: rotate(45deg);
            }
            &:after {
                transform: rotate(-45deg);
            }
        }
    }
    .nav {
        display: block;
        transform: translateY(0);
        height: 100vh;
        overflow: auto;
        padding-bottom: 20px;
    }
}

.botonera-prenav {
    border-top: 2px solid $secondary-color;
    @include desktop {
        display: none;
    }
    img {
        max-width: get-rem(25px);
        margin-left: get-rem(10px);
    }
}


/*JORGE*/