main {
    margin-top: get-rem(100px);
    padding-bottom: get-rem(40px);
    @include desktop {
        margin-top: get-rem(200px);
        padding-bottom: get-rem(40px);
    }
    .content {
        border-top-right-radius: get-rem(50px);
        background: $quinary-color;
        padding-top: get-rem(60px);
        padding-bottom: get-rem(100px);
        @include desktop {
            padding-left: get-rem(129px);
            padding-right: get-rem(129px);
        }

        margin-bottom: get-rem(20px);
        font-weight: 300;
        line-height: 1.2;
        color: $primary-color;

        picture{
            margin-bottom: 20px;
            display: block;
        }

    }
    .content-privatearea {
        background: $quinary-color;
        border-top-right-radius: get-rem(50px);
        padding-left: 0;
        padding-right: 0;
        padding-top: 0;
    }
}

.home {
    main {
        background-position: top;
        background-image: url(/img/home-background-mobile.jpg);
        background-repeat: no-repeat;
        background-size: cover;
        @include tablet {
            background-image: url(/img/home-background.jpg);
        }
        .title {
            display: flex;
            align-items: flex-start;
            padding-top: get-rem(20px);
            padding-bottom: get-rem(190px);
            @include tablet {
                padding-left: get-rem(130px);
                padding-top: get-rem(77px);
                padding-bottom: get-rem(182px);
            }
            @include desktop {
                padding-bottom: get-rem(210px);
            }
            h1 {
                color: $secondary-color;
                font-size: get-rem(32px);
                font-weight: 300;
                @include desktop {
                    font-size: get-rem(38px);
                }
                span {
                    color: $tertiary-color;
                }
            }
            h2 {
                padding-top: get-rem(14px);
                font-size: get-rem(18px);
                font-weight: 600;
                @include desktop {
                    font-size: get-rem(22px);
                }
            }
            &__logo {
                max-width: get-rem(140px);
                left: get-rem(20px);
                top: get-rem(30px);
                @include tablet {
                    max-width: get-rem(165px);
                    position: absolute;
                }
            }
            &__texto {
                @include mobile {
                    margin-left: get-rem(-30px);
                    margin-top: get-rem(50px);
                }
            }
        }
        .cartela {
            background-color: $quinary-color;
            padding: get-rem(60px) get-rem(20px);
            margin-bottom: get-rem(40px);
            display: flex;
            flex-wrap: wrap;
            @include mobile {
                opacity: 0.90;
            }
            @include tablet {
                padding: get-rem(53px) get-rem(50px);
                @include border-radius;
                display: flex;
            }
            &__title {
                margin-bottom: get-rem(33px);
                @include desktop {
                    margin-bottom: 0;
                }
                span {
                    color: $secondary-color;
                    display: block;
                    &:nth-child(1) {
                        font-size: get-rem(28px);
                        font-weight: 500;
                    }
                    &:nth-child(2) {
                        font-size: get-rem(40px);
                        font-weight: 500;
                    }
                    &:nth-child(3) {
                        font-size: get-rem(27px);
                        font-weight: 300;
                    }
                }
            }
            &__menu {
                ul {
                    display: flex;
                    flex-wrap: wrap;
                }
            }
            &__link {
                display: block;
                padding-top: get-rem(14px);
                margin-right: get-rem(37px);
                color: $secondary-color;
                text-decoration: underline;
                display: flex;
                align-items: center;
                font-size: get-rem(19px);
                @include tablet {
                    font-size: inherit;
                }
            }
            &__icono {
                max-width: 18px;
                margin-right: get-rem(7px);
            }
        }
        .cards {
            display: inline-flex;
            width: 100%;
            gap: 4%;
            flex-wrap: wrap;
            justify-content: center;
            margin-bottom: get-rem(10px);
            justify-content: center;
            .card {
                background: $quinary-color;
                @include border-radius;
                min-height: get-rem(290px);
                position: relative;
                background-size: cover;
                flex: 0 0 100%;
                max-width: 100%;
                margin-bottom: get-rem(40px);
                @include tablet {
                    flex: 0 0 48%;
                    max-width: 48%;
                }
                &__text {
                    color: $secondary-color;
                    text-align: right;
                    position: absolute;
                    right: get-rem(40px);
                    top: get-rem(40px);
                    h2 {
                        font-size: get-rem(33px);
                        font-weight: 500;
                    }
                    h3 {
                        font-size: get-rem(24px);
                        font-weight: 300;
                        margin-bottom: get-rem(6px);
                    }
                }
                &__button {
                    position: absolute;
                    right: get-rem(40px);
                    bottom: get-rem(28px);
                }

                &.vih {
                    background-image: url(../img/vih-background.jpg)
                }
                &.hepaticas {
                    background-image: url(../img/enfermedades-hepaticas-background.jpg)
                }
                &.oncologia {
                    background-image: url(../img/hemato-oncologia-background.jpg)
                }
                &.covid {
                    background-image: url(../img/covid-background.jpg)
                }
                &.tumores {
                    background-image: url(../img/oncologia-medica-background.jpg)
                }
                                
            }
        }
        .instituto {
            background-color: $quinary-color;
            padding: get-rem(40px) get-rem(40px) get-rem(23px) get-rem(40px);
            p.titulo {
                color: $secondary-color;
                font-size: get-rem(38px);
                font-weight: 300;
                max-width: get-rem(330px);
                margin-bottom: get-rem(5px);
            }
            p {
                font-size: get-rem(21px);
                color: $tertiary-color;
                font-weight: 300;
                line-height: 1.1;
            }
            .logo {
                @include mobile {
                    margin-top: get-rem(40px);
                }
            }
        }
    }
}


/* ------ INTRO ------ */

.intro {
    main {
        background-position: top;
        background-repeat: no-repeat;
        background-size: cover;


        .timeline {
            margin-left: get-rem(35px);
            @include desktop {
                margin-left: 0
            }
            &__fecha {
                color: $quinary-color;
                background: url(/img/timeline-date-bakcground@2x.png);
                width: get-rem(61px);
                height: get-rem(38px);
                background-size: cover;
                display: flex;
                padding-top: get-rem(4px);
                justify-content: center;
                left: get-rem(-5px);
                top: get-rem(5px);
                position: relative;
            }
            ul {
                position: relative;
                &:before {
                    content: "";
                    position: absolute;
                    top: 10px;
                    left: get-rem(-25px);
                    height: calc(100% - 50px);
                    width: 1px;
                    border: 2px dashed $secondary-color;
                }
                p {
                    font-weight: 500;
                }
                li {
                    margin-bottom: get-rem(20px);
                    position: relative;
                    &.first {
                        padding-top: 0;
                        &::before {}
                    }
                    &::before {
                        content: "●";
                        top: 0px;
                        font-size: get-rem(30px);
                        position: absolute;
                        left: get-rem(-32px);
                        color: $secondary-color;
                    }
                }
            }
        }
    }
}


/* ------ BECAS 10º EDICIÓN ------ */

.becas {

    .foto-superior {
        padding-bottom: get-rem(56px);
    }

    main {
        background-position: top;
        background-repeat: no-repeat;
        background-size: cover;
    }
    .logo-instituto {
        padding-bottom: get-rem(56px);
    }
    .lista-custom-bullet {
        padding-left: get-rem(50px);
        padding-bottom: get-rem(50px);
        list-style-image: url(/img/bullet.png);
        li {
            margin-bottom: get-rem(15px);
        }
    }
    .listado-documentos {
        margin-left: get-rem(60px);
        padding-bottom: get-rem(50px);
        list-style-type: none;
        li {
            border-bottom: 2px solid #e8e8e8;
            position: relative;
            list-style: none !important;
            &:last-child {
                border: 0;
            }
            a {
                color: inherit;
                display: block;
                padding-top: get-rem(16px);
                padding-bottom: get-rem(16px);
                &.pdf {
                    &::before {
                        content: "";
                        position: absolute;
                        top: get-rem(8px);
                        left: get-rem(-60px);
                        background: url('/img/pdf-icon@2x.png') no-repeat left top;
                        background-size: get-rem(35px) get-rem(42px);
                        padding-left: get-rem(60px);
                        width: 100%;
                        height: 100%;
                    }
                }
                &.doc {
                    &::before {
                        content: "";
                        position: absolute;
                        top: get-rem(8px);
                        left: get-rem(-60px);
                        background: url('/img/doc-icon@2x.png') no-repeat left top;
                        background-size: get-rem(35px) get-rem(42px);
                        padding-left: get-rem(60px);
                        width: 100%;
                        height: 100%;
                    }
                }
            }
            p {
                font-weight: 500;
            }
        }
    }
}


/* ------ EDICIONES ANTERIORES ------ */

.ediciones-anteriores {
    main {
        background-position: top;
        background-repeat: no-repeat;
        background-size: cover;

        .listado-ediciones {
            .edicion {
                &__titulo {
                    border-bottom: 2px solid $secondary-color;
                    padding-bottom: 0;
                    position: relative;
                    margin-bottom: get-rem(12px);
                    &::before {
                        content: "";
                        position: absolute;
                        top: 0;
                        left: get-rem(-50px);
                        background: url('/img/folder-icon@2x.png') no-repeat left top;
                        background-size: get-rem(36px) get-rem(30px);
                        padding-left: get-rem(60px);
                        width: 100%;
                        height: 100%;
                    }
                }
                &__textoseimagen {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                }
                &__textos {
                    p {
                        margin-bottom: get-rem(6px);
                        font-weight: 400;
                        span {
                            display: inline-block;
                            color: $secondary-color;
                        }
                    }
                    a{
                        color: $primary-color;

                    }
                }
            }
        }
    }
}


/* ------ ÁREA PRIVADA ------ */

.areaprivada {
    .columna-menu {
        flex: 0 0 25%;
        background: #f5f5f5;
        display: block;
        @include mobile {
            flex: 0 0 100%;
        }
    }
    .columna-contenido {
        flex: 0 0 100%;
        @include desktop {
            flex: 0 0 75%;
        }
    }
    h1 {
        padding-top: get-rem(130px);
        @include tablet {
            padding-top: get-rem(130px);
        }
        @include mobile {
            display: none;
        }
    }
    main {
        background-position: top;
        background-image: url(/img/areaprivada-background.jpg);
        background-repeat: no-repeat;
        background-size: cover;
        @include tablet {
            background-image: url(/img/areaprivada-background.jpg);
        }
    }
    
    .privatearea-menu {
        background: #f5f5f5;
        padding-top: get-rem(40px);
        position: fixed;
        width: 100%;
        top: get-rem(160px);
        z-index: 5;
        transition: ease 0.5s;
        transform: translateX(-100%);
        @include desktop {
            transform: translateX(0);
            height: 100%;
            position: relative;
            top: get-rem(0);
            padding-top: get-rem(80px);
        }
        &__list {
            width: calc(100% - 74px);
            margin: auto;
        }
        &__link {
            padding-top: get-rem(20px);
            padding-bottom: get-rem(20px);
            display: block;
            border-bottom: 1px solid #dadada;
            font-size: get-rem(19px);
            text-align: right;
            cursor: pointer;
            color: inherit;
            &:hover {
                color: $secondary-color;
                font-weight: 600;
            }
            &.active {
                color: $secondary-color;
                font-weight: 600;
            }
            &-last {
                border: 0;
            }
        }
    }
    .pdf-module a {

        border-top: 5px solid $quinary-color;
        border-bottom: 5px solid $quinary-color;
        display: flex;
        cursor: pointer;
        align-items: center;
        justify-content: flex-start;

        p {
            padding-left: get-rem(37px);            
            text-align: right;
            font-weight: 300;
            margin-top: get-rem(20px);

        }

        img {
            max-width: get-rem(35px);
            margin-left: get-rem(15px);
        }
    }
    .hamburgermenu-areaprivada {
        position: fixed;
        top: get-rem(103px);
        background: $quinary-color;
        width: 100%;
        height: get-rem(60px);
        z-index: 8;
        @include desktop {
            display: none;
        }
    }
    .privatearea-contenido {
        padding-top: get-rem(80px);
        padding-left: $gutter;
        padding-right: $gutter;
        @include desktop {
            padding-left: get-rem(50px);
            padding-right: get-rem(210px);
            padding-top: get-rem(92px);
            padding-bottom: get-rem(40px);
        }
        &__titulo {
            padding-bottom: get-rem(57px);
        }
        .lista-custom-arrow {
            padding-left: get-rem(50px);
            padding-bottom: get-rem(50px);
            list-style-image: url(/img/bullet-arrow.png);
            li {
                margin-bottom: get-rem(15px);
            }
        }
    }
    &.login {
        .columna-contenido {
            flex: 0 0 100%;
            margin: auto;
            @include tablet {
                flex: 0 0 60%;
            }
            .recordar-password {
                margin-top: get-rem(40px);
                a {
                    color: $secondary-color;
                    text-align: center;
                    margin: auto;
                    display: block;
                    font-size: get-rem(16px);
                    cursor: pointer;
                }
            }
        }
        .privatearea-contenido {
            @include desktop {
                padding-left: get-rem(50px);
                padding-right: get-rem(50px);
            }
        }
    }
    .capa-pdf {
        display: flex;
        align-items: center;
        p {
            margin-left: get-rem(14px);
            font-size: get-rem(15px);
            color: #999999;
        }
    }
}


/* ------ BREADCRUMBS ------ */

.breadcrumbs {
    padding-bottom: get-rem(100px);
    ul {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-end;
        li {
            padding: 0 get-rem(14px);
            margin-bottom: get-rem(10px);
            color: $secondary-color;
            /*text-transform: capitalize;*/

            &:first-letter {
                text-transform: uppercase;
            }

            @include desktop {
                border-right: 1px solid $secondary-color;
            }
            &:last-child {
                border: 0;
            }
            &:first-child{
                display: block;
                color: #999999;
            }
            a {
                display: block;
                color: #999999;
                &.active {
                    color: #999999;
                }
            }
        }
    }
}


/* ------ BREADCRUMBS ------ */

.entradilla{
    padding-bottom: get-rem(20px);

    ul {
        display:list-item;
        padding-left: get-rem(20px);
        
        li {
            padding: 0 get-rem(14px);
            margin-bottom: get-rem(10px);
            font-size: get-rem(22px);
            list-style: disc;

        }
    }
}


/* ------ CONTENIDOS ------ */

.container{


    .content {

        p{ 
            a{color: #b60030;}
        }

        ul{
            margin-left: 20px;
            margin-top: 10px;
            li{
                list-style: disc;
                ul{
                    margin-left: 20px; 
                    li{
                        list-style:circle; 
                    }
                }
            }
        }

        nav{
            ul{
                li{
                    list-style: none !important;
                }
            }
        }

    }
}



/* ------ CONTACT FORM ------ */

.contactform {
    margin-bottom: get-rem(55px);
    textarea {
        resize: none;
    }
    label {
        margin-bottom: get-rem(5px);
        display: block;
    }
    a {
        color: inherit;
        text-decoration: underline;
    }
    .field-wrap {
        margin-bottom: get-rem(20px);
        input {
            border: 1px solid #dbdbdb;
            border-radius: get-rem(7px);
            padding: get-rem(13px);
            width: 100%;
            font-size: get-rem(20px);
            font-weight: 300;
            font-family: $roboto;
        }
        textarea {
            @extend input;
            height: get-rem(180px);
        }

        select {
            @extend input;
        }        
        
        .upload-image[type=file] {
            max-width: 100%;
            padding:  get-rem(5px);
            background: #fff;
            border-radius:  get-rem(8px);
            border: 1px solid $secondary-color;
            font-size: get-rem(14px);
            
            @include desktop{
               width: 100%;  
            }
        }
       .upload-image[type=file]::file-selector-button {
            margin-right:  get-rem(20px);
            border: none;
            background: $secondary-color;
            padding:  get-rem(10px) get-rem(20px);
            border-radius: 10px;
            color: #fff;
            cursor: pointer;
            transition: background .2s ease-in-out;
        }
        .upload-image[type=file]::file-selector-button:hover {
            background: $secondary-color;
        }
    }
    .not-valid {
        color: $secondary-color;
        font-size: get-rem(14px);
        margin-top: get-rem(10px);
        display: none;
    }
    .acceptance {
        display: flex;
        margin-bottom: get-rem(10px);
        @include desktop {
            max-width: get-rem(554px);
        }
        p {
            font-size: get-rem(15px);
        }

        span {


        }
    }
    .checkbox {
        display: block;
        position: relative;
        padding-left: get-rem(30px);
        cursor: pointer;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
    }
    .checkbox input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
    }
    .checkmark {
        position: absolute;
        top: 0;
        left: 0;
        height: get-rem(18px);
        width: get-rem(18px);
        border: 1px solid $secondary-color;
        border-radius: get-rem(4px);
    }
    .checkmark:after {
        content: "";
        position: absolute;
        display: none;
    }
    .checkbox input:checked ~ .checkmark:after {
        display: block;
    }
    .checkbox .checkmark:after {
        left: get-rem(4px);
        top: 0;
        width: get-rem(8px);
        height: get-rem(13px);
        border: solid $secondary-color;
        border-width: 0 3px 3px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
    }
    .btn-enviar {
        margin-top: get-rem(40px);
        width: 100%;
        text-align: center;
        input {
            background: $secondary-color;
            border-radius: get-rem(4px);
            color: $quinary-color;
            font-size: get-rem(20px);
            border: 0;
            padding: get-rem(10px) get-rem(40px);
            cursor: pointer;
        }
    }


    .legalesFrm{
        border-top: solid 1px $secondary-color;
        margin-top: 30px;
        

        p{
            padding-top: 20px;
            font-size: 12px;
        }
        

    }


    #barritacookies{
        display:none;
        position:fixed;
        left:0px;
        right:0px;
        bottom:0px;
        width:100%;
        min-height:280px;
        background: rgba(0, 0, 0, 0.8);
        color:#ffffff;
        z-index: 99999;
        text-align: center;

        @include desktop {
            min-height:80px;
        }

        

    }
    
    
}