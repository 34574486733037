*,
::after,
::before {
    box-sizing: border-box;
}

:root {
    font-family: $roboto;
    text-rendering: optimizeLegibility;
}

html {
    scroll-behavior: smooth;
}

body {
    font-size: get-rem(20px);
}

p {
    margin-bottom: get-rem(20px);
    font-weight: 300;
    line-height: 1.2;
    color: $primary-color;

    &:last-child {
        margin-bottom: 0;
    }
}


h1 {
    padding-top: get-rem(20px);
    font-size: get-rem(38px);
    width: 100%;
    padding-top: get-rem(56px);
    padding-bottom: get-rem(56px);
    font-weight: 300;
    color: $secondary-color;
    padding-left: get-rem(30px);
    padding-right: get-rem(30px);

    @include desktop {
        padding-left: get-rem(129px);
        font-size: get-rem(48px);
    }


    span {
        font-size: get-rem(31px);
        display: block;
    }
}


h2 {
    font-size: get-rem(28px);
    color: $secondary-color;
    font-weight: bold;
    padding-bottom: get-rem(60px);
    line-height: 1.2;
    text-transform: uppercase;

    @include desktop {
        font-size: get-rem(30px);
    }
}

h3 {
    @extend h2;
    padding-bottom: get-rem(20px);
    font-size: get-rem(20px);

    .number {
        border-radius: 50%;
        background: $secondary-color;
        color: $quinary-color;
        width: get-rem(50px);
        height: get-rem(50px);
        display: inline-flex;
        align-items: center;
        justify-content: center;
        margin-right: get-rem(8px);

    }
}


h4{
    color: $secondary-color;
    font-size: get-rem(34px);
}


strong {
    font-weight: 500;
}

.gothammedium {
    font-family: 600;
}

img {
    max-width: 100%;
    height: auto;
}

.txt-red {
    color: $secondary-color;
}

.big-text {
    font-size: get-rem(34px);
}

.button {

    color: $tertiary-color;
    -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.35);
    -moz-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.35);
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.35);
    border-radius: get-rem(20px);
    padding: get-rem(4px) get-rem(14px) get-rem(4px) get-rem(20px);
    display: inline-flex;
    background-color: $quinary-color;
    align-items: center;

    span {
        color: $secondary-color;
        font-size: get-rem(30px);
        margin-left: get-rem(10px);
    }
}

.mostrarMenu{
display: block !important;
}

.mostrar{display: block !important;}
.ocultar{display: none !important;}

.desplegadoMenu{
    height: 100%;
    overflow-y: auto;
}

.hide-desktop {
    display: block;

    @include tablet {
        display: none;
    }
}

.hide-tablet {
    @include tablet {
        display: none;
    }
    
        @include desktop {
        display: block;
    }
}


.hide-mobile {
    @include mobile {
        display: none;
    }
}

.image-mobile {
    @include tablet {
        display: none;
    }
}

.image-desktop {

    display: none;

    @include tablet {
        display: block;
    }
}


.d-flex {
    display: flex !important;
}

/* ALIGN ITEMS */

.align-items-start {
    -webkit-box-align: start !important;
    -ms-flex-align: start !important;
    align-items: flex-start !important;
}

.align-items-end {
    -webkit-box-align: end !important;
    -ms-flex-align: end !important;
    align-items: flex-end !important;
}

.align-items-center {
    -webkit-box-align: center !important;
    -ms-flex-align: center !important;
    align-items: center !important;
}

.align-items-baseline {
    -webkit-box-align: baseline !important;
    -ms-flex-align: baseline !important;
    align-items: baseline !important;
}

.align-items-stretch {
    -webkit-box-align: stretch !important;
    -ms-flex-align: stretch !important;
    align-items: stretch !important;
}


/* JUSTIFY CONTENT*/

.justify-content-start {
    -webkit-box-pack: start !important;
    -ms-flex-pack: start !important;
    justify-content: flex-start !important;
}

.justify-content-end {
    -webkit-box-pack: end !important;
    -ms-flex-pack: end !important;
    justify-content: flex-end !important;
}

.justify-content-center {
    -webkit-box-pack: center !important;
    -ms-flex-pack: center !important;
    justify-content: center !important;
}

.justify-content-between {
    -webkit-box-pack: justify !important;
    -ms-flex-pack: justify !important;
    justify-content: space-between !important;
}

.justify-content-around {
    -ms-flex-pack: distribute !important;
    justify-content: space-around !important;
}

@include tablet {
    .justify-content-start-md {
        -webkit-box-pack: start !important;
        -ms-flex-pack: start !important;
        justify-content: flex-start !important;
    }

    .justify-content-end-md {
        -webkit-box-pack: end !important;
        -ms-flex-pack: end !important;
        justify-content: flex-end !important;
    }

    .justify-content-center-md {
        -webkit-box-pack: center !important;
        -ms-flex-pack: center !important;
        justify-content: center !important;
    }

    .justify-content-between-md {
        -webkit-box-pack: justify !important;
        -ms-flex-pack: justify !important;
        justify-content: space-between !important;
    }

    .justify-content-around-md {
        -ms-flex-pack: distribute !important;
        justify-content: space-around !important;
    }
}

@include desktop {
    .justify-content-start-lg {
        -webkit-box-pack: start !important;
        -ms-flex-pack: start !important;
        justify-content: flex-start !important;
    }

    .justify-content-end-lg {
        -webkit-box-pack: end !important;
        -ms-flex-pack: end !important;
        justify-content: flex-end !important;
    }

    .justify-content-center-lg {
        -webkit-box-pack: center !important;
        -ms-flex-pack: center !important;
        justify-content: center !important;
    }

    .justify-content-between-lg {
        -webkit-box-pack: justify !important;
        -ms-flex-pack: justify !important;
        justify-content: space-between !important;
    }

    .justify-content-around-lg {
        -ms-flex-pack: distribute !important;
        justify-content: space-around !important;
    }
}


/* ORDER */

.order-1 {
    order: 1 !important;
}

.order-2 {
    order: 2 !important;
}

.order-3 {
    order: 3 !important;
}

.order-4 {
    order: 4 !important;
}


@include tablet {

    .order-md-1 {
        order: 1 !important;
    }

    .order-md-2 {
        order: 2 !important;
    }

    .order-md-3 {
        order: 3 !important;
    }

    .order-md-4 {
        order: 4 !important;
    }
}


@include desktop {
    .order-lg-1 {
        order: 1 !important;
    }

    .order-lg-2 {
        order: 2 !important;
    }

    .order-lg-3 {
        order: 3 !important;
    }

    .order-lg-4 {
        order: 4 !important;
    }
}


.nolink{
    cursor:default;


}

.listado_areas{
   /* font-size: 13px !important;*/
}

.mostrar_menu_privado{
    transform: none !important;
}

/* FONT FACE */
