

//reset
@import "partials/reset";

//Fonts
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap');

//Core
@import "partials/variables";
@import "partials/pxtorem";
@import "partials/mixins";
@import "partials/grid";
@import "partials/general";

//Sections
@import "partials/main";
@import "partials/header";
@import "partials/footer";




