
@mixin center() {
    margin: 0 auto;
    left: 0;
    right: 0;
    position: absolute;
}

@mixin border-radius(){
border-radius:  get-rem(22px);
}


@mixin mobile {
    @media (max-width: #{$screen-xs-max}) {
        @content;
    }
}

@mixin tablet {
    @media (min-width: #{$screen-md-min}) {
        @content;
    }
}

@mixin desktop {
    @media (min-width: #{$screen-lg-min}) {
        @content;
    }
}

@mixin desktoplarge {
    @media (min-width: #{$screen-xl-min}) {
        @content;
    }
}

@mixin gridGenerator($breakpoint, $offset: false) {

    /*------------------------------------*\
    # COLUMNS
    \*------------------------------------*/
    @for $column from 1 to $columns-number + 1 {
        .col-#{$breakpoint}-#{$column} {
            // flex-basis: percentage($column / $columns-number);
            width: (100% * $column) / $columns-number!important;

        }
    }

    /*------------------------------------*\
    # OFFSET COLUMNS
    \*------------------------------------*/
    @if $offset {
        @for $column from 1 to $columns-number + 1 {
            .col-#{$breakpoint}-offset-#{$column} {
                margin-left: (100% * $column) / $columns-number;
            }
        }
    }
}


@include mobile {
    @include gridGenerator($breakpoint: 'xs');
}

@include tablet {
    @include gridGenerator($breakpoint: 'md');
}

@include desktop {
    @include gridGenerator($breakpoint: 'lg');
}

@include desktoplarge {
    @include gridGenerator($breakpoint: 'xl');
}
