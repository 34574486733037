.hide {
    display: none;
}

.footer {
    padding-top: get-rem(27px);
    padding-bottom: get-rem(50px);
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    background: $quinary-color;
    width: 100%;


    &__logo {
        max-width: get-rem(160px);
    }

    &__menu {
        padding-top: get-rem(30px);
    }

    &__lista {
        text-align: center;
        font-size: get-rem(15px);
        @include tablet {
            display: flex;
           
        }
    }


    &__lista-elemento {
        position: relative;
        margin-bottom:  get-rem(20px);

        &.first-element{
            @include tablet{
                padding-right:  get-rem(10px);
            }
        }
    }

    &__link {
        color: inherit;

        @include tablet{
            padding: 0 get-rem(10px) 0 get-rem(10px);
            border-left: 1px solid $primary-color;
        }

        &:hover {
            color: $secondary-color;
            text-decoration: underline;
        }
    }

}