//Colors
$primary-color: #333333;
$secondary-color: #b60030;
$tertiary-color:#666666;
$quaternary-color:#000000;
$quinary-color:#ffffff;
/*$senary:#F2F2F4;*/

//Text 
$roboto: 'Roboto', sans-serif;



// Border Radius
$border-radius:4px;

//Grid
$container-max-width: 1220px;
$columns-number: 12;
$gutter: 15px;
$gutterdesktop: 30px;
$externalgutter:15px;
$externalgutterdesktop:50px;


//Breakpoints


// Small devices (portrait phones, 576px and down
$screen-xs-max: 576px;
// Medium devices (landscape phone, 768px and up)
$screen-md-min: 768px;
// Large devices (desktops, 992px and up)
$screen-lg-min: 992px;
// X-Large devices (large desktops, 1200px and up)
$screen-xl-min:1200px;