@include mobile {
    @include gridGenerator($breakpoint: 'xs', $offset: true);
}

@include tablet {
    @include gridGenerator($breakpoint: 'md', $offset: true);
}

@include desktop {
    @include gridGenerator($breakpoint: 'lg', $offset: true);
}

@include desktoplarge {
    @include gridGenerator($breakpoint: 'xl', $offset: true);
}

.container {
    max-width: $container-max-width;
    margin: 0 auto;
    padding-left: $externalgutter;
    padding-right: $externalgutter;
}

.container-fluid {
    width: 100%;
    padding-left: $externalgutter;
    padding-right: $externalgutter;
}

.row {
    display: flex;
    flex-wrap: wrap;
    margin-left: -$gutter;
    margin-right: -$gutter;
    //  margin-left: -0.526%;
    //  margin-right:-0.526%;
    // margin-left: -1.052%;
    // margin-right: -1.052%;
}

.col {
    flex-shrink: 1;
    flex-basis: 0;
    flex-grow: 1;
}

[class*=col] {
    padding: 0 $gutter;
    // padding: 0 1.052%;
    position: relative;
    width: 100%;
}

.no-gutter {
    margin-right: 0;
    margin-left: 0;
}

.row.no-gutter [class*=col] {
    padding-left: 0;
    padding-right: 0;
}

[class*=col].no-gutter {
    padding-left: 0;
    padding-right: 0;
}


.no-padding {
    padding-left: 0!important;
    padding-right: 0!important;
}

.no-padding-mobile {
    @include mobile {
        padding-left: 0;
        padding-right: 0;
    }
}

.no-margin-mobile {
    @include mobile {
        margin-left: 0;
        margin-right: 0;
    }
}